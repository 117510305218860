.input-group select.form-control:first-child {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: .5em;
    padding-right: 1.5em;
    @include vendor(border-radius, 4px 0 0 4px !important);
}

form {
	.active {
    	color: green;
	}
	&.form-inline {
	    display: inline-block;
	}
}

.switch {
    cursor: pointer;

	.fa {
	    vertical-align: -5px;
	    margin-right: .25em;
	}

	.active {
	    display: none;
	}

	input[type="radio"] {
	    position: relative;
	    z-index: -1;
	}
	&.checked {
		.active {
		    display: inline-block;
		}
		.inactive {
		    display: none;
		}
	}
}

.disabled {
    color: #dcdbdb;
    pointer-events: none;
    cursor: not-allowed;

	.switch span.active {
	    color: #dcdbdb;
	    pointer-events: none;
	    cursor: not-allowed;
	}
}

label {

	input[type="radio"] ~ i.fa.fa-circle-o{
	    color: #c8c8c8;    display: inline;
	}
	input[type="radio"] ~ i.fa.fa-dot-circle-o{
	    display: none;
	}
	input[type="radio"]:checked ~ i.fa.fa-circle-o{
	    display: none;
	}
	input[type="radio"]:checked ~ i.fa.fa-dot-circle-o{
	    color: #7AA3CC;    display: inline;
	}

	input[type="checkbox"] ~ i.fa.fa-square-o{
	    color: #c8c8c8;    display: inline;
	}
	input[type="checkbox"] ~ i.fa.fa-check-square-o{
	    display: none;
	}
	input[type="checkbox"]:checked ~ i.fa.fa-square-o{
	    display: none;
	}
	input[type="checkbox"]:checked ~ i.fa.fa-check-square-o{
	    color: #7AA3CC;    display: inline;
	}

	input[type="checkbox"]:checked ~ i.fa.fa-check-square-o {
	    color: $brand-danger !important;
	    display: inline;
		left: 2px;
	}

	&:hover {
		input[type="radio"] ~ i.fa {
			color: #7AA3CC;
		}

		input[type="checkbox"] ~ i.fa {
			color: #7AA3CC;
		}
	}

}

div[data-toggle="buttons"] {
	label {
		&:active,
		&.active {
			@include vendor(box-shadow, none);
		}
	}
}

.btn.no-shadow .fa {
	float: left;
	left: 0;
	position: absolute;
	width: 40px;
}