.list-group-responsive {
	span{
	    display: block;
	    overflow-y: auto;
	}
}

.theme-details-list {
	strong {
	    width: 5.5em;
	    display: inline-block;
	    position: absolute;
	}
	span {
	    margin-left: 5.5em;
	}
}