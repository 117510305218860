.no-padding {
    padding: 0;
}

.start-hidden {
	display: none;
}

.no-shadow {
	@include vendor(box-shadow, none !important);
	@include vendor(outline, none !important);
	-webkit-appearance: none !important;
}

.border-bottom {
	border-bottom:1px #f8f8f8 solid;
	margin:5px 0  5px 0;
}

.border-radius {
	@include vendor(border-radius, $standard-radius);
}

@media(max-width: $mq-tiny) {
	.hidden-xxxs {
		display: none;
	}
}

@media(max-width: $phone) {
	.hidden-xxs {
		display: none;
	}
}
